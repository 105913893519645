import { AxiosStatic } from "axios";

export function setupAxios(axios: AxiosStatic) {
  // Requests default headers
  axios.defaults.headers.common["Accept"] = "application/json";

  // Response interceptor for API calls
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;

      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        return axios(originalRequest);
      }

      return Promise.reject(error);
    }
  );
}
