import classNames from "classnames";

import useGem from "./useGem";

import "./Gem.css";
import { Artist } from "./useGems";

interface GemProps {
  bottom: number;
  left: number;
  imageUrl: string;
  dinoRef: React.RefObject<HTMLDivElement>;
  isTaken: boolean;
  id: number;
  artist?: Artist
  onTakeGem: (id: number) => void;
}

const Gem = ({
  bottom,
  left,
  imageUrl,
  dinoRef,
  isTaken,
  id,
  onTakeGem,
  artist
}: GemProps) => {
  const { gemRef } = useGem({ dinoRef, id, isTaken, onTakeGem });

  return (
    <div
      className={classNames("gem-wrapper", { taken: isTaken, "gem-artist": artist, "gem-default": !artist })}
      style={{
        bottom,
        left,
      }}
      ref={gemRef}
    >
      {!artist && (<img  src={imageUrl} alt=""  width={70}/>)}
      {artist && (<img src={artist && artist.imageUrl} alt="" height={120}/>)}
    </div>
  );
};

export default Gem;
