import Dino from "./Dino";
import useDinoFamily from "./useDinoFamily";
import { gemImageUrls } from "./constants";
import Clairiere from "./assets/scene.png";

import "./DinoFamily.css";

const gemsToTransfer = Array(30)
  .fill(undefined)
  .map(() => gemImageUrls[Math.floor(Math.random() * gemImageUrls.length)]);

interface DinoFamilyProps {
  dinoSpeed: number;
  stopDino: () => void;
}

const DinoFamily = ({ dinoSpeed, stopDino }: DinoFamilyProps) => {
  const { position, areGemsTransferring } = useDinoFamily({
    dinoSpeed,
    stopDino,
  });

  return (
    <div className="dino-family-wrapper" style={{ left: position }}>
      <img src={Clairiere} alt="cla" style={{height:"800px"}}></img>
    </div>
  );
};

export default DinoFamily;
