import Sky from "./Sky";
import Hills from "./Hills";
import Gems from "./Gems";
import Dino from "./Dino";
import Ground from "./Ground";
import Train from "./Train";
import Splash from "./Splash";

import useGame from "./useGame";
import useTrain from "./useTrain";
import useDino from "./useDino";

import "./App.css";
import DinoFamily from "./DinoFamily";
import CountdownLoading from "./Countdown";
import { useEffect } from "react";

import Ambiance from "./assets/sound/fx/AMBIANCE.mp3";


interface Props {
  onStartGameWrapper: () => void;
  onFinishGameWrapper: () => void;
  timeToDisplay: string;
}

const GameWrapper = ({ onStartGameWrapper, onFinishGameWrapper,timeToDisplay }: Props) => {
  const {
    isCountdownVisible,
    setIsCountdownVisible,
    isGameFinished,
    onFinishGame,
  } = useGame();

  const { isTrainGoing, onStartTrain, onStopTrain, trainRef } = useTrain();

  const {
    dinoState,
    dinoPosition,
    dinoSpeed,
    isDinoFlipping,
    isDinoOnTrain,
    startDino,
    stopDino,
    dinoRef,
  } = useDino({ isGameFinished, trainRef });

  useEffect(() => {
    if (isCountdownVisible === true) {
      onStartGameWrapper();
    }
  }, [isCountdownVisible]);

  useEffect(() => {
    if (isGameFinished === true) {
        onFinishGameWrapper();
    }
  }, [isGameFinished, dinoState]);

  useEffect(()=>{
    var ambiance = new Audio(Ambiance)
    ambiance.loop = true
    ambiance.volume=0.05
    ambiance.play()
  },[])
  return (
    <>
      <div className="game-canvas">
        <div>
          <Sky dinoSpeed={dinoSpeed} />
          <Hills dinoSpeed={dinoSpeed} />
          {isTrainGoing && (
            <Train
              dinoSpeed={dinoSpeed}
              trainRef={trainRef}
              onStopTrain={onStopTrain}
            />
          )}
          <Gems
            dinoSpeed={dinoSpeed}
            dinoRef={dinoRef}
            isDinoOnTrain={isDinoOnTrain}
            onStartTrain={onStartTrain}
            onFinishGame={onFinishGame}
            timeToDisplay={timeToDisplay}
          />
          <Dino
            state={dinoState}
            position={dinoPosition}
            isFlipping={isDinoFlipping}
            dinoRef={dinoRef}
            isMain
          />
          {isGameFinished && (
            <DinoFamily dinoSpeed={dinoSpeed} stopDino={stopDino} />
          )}
        </div>
        <div>
          <Ground dinoSpeed={dinoSpeed} />
        </div>
      </div>
       <CountdownLoading onHide={startDino} />
    </>
  );
};

export default GameWrapper;
