import Gem1 from "./assets/cd2.png";
import Gem2 from "./assets/cd2.png";
import Gem3 from "./assets/cd2.png";
import Gem4 from "./assets/cd2.png";
import Gem5 from "./assets/cd2.png";
import Gem6 from "./assets/cd2.png";

import Argy from "./assets/artists/argy.png";
import Bakermat from "./assets/artists/Bakermat.png";
import BobSinclar from "./assets/artists/Bob_Sinclar.png";
import Feder from "./assets/artists/Feder.png";
import Fisher from "./assets/artists/Fisher.png";
import Joris from "./assets/artists/Joris.png";
import Jungle from "./assets/artists/Jungle.png";
import Klingande from "./assets/artists/klingande.png";
import Locodice from "./assets/artists/locodice.png";
import Maklovitch from "./assets/artists/maklovitch.png";
import Malaa from "./assets/artists/malaa.png";
import Mochakk from "./assets/artists/Mochakk.png";
import Pastor from "./assets/artists/pastor.png";
import PDM from "./assets/artists/PDM.png";
import StellaBossi from "./assets/artists/Stella_Bossi.png";
import Worakls from "./assets/artists/worakls.png";

import ArgySound from "./assets/sound/artists/argy.mp3";
import BakermatSound from "./assets/sound/artists/bakermat.mp3";
import BobSinclarSound from "./assets/sound/artists/bob_sinclar.mp3";
import MaklovitchSound from "./assets/sound/artists/brothers.mp3";
import FederSound from "./assets/sound/artists/feder.mp3";
import FisherSound from "./assets/sound/artists/fisher.mp3";
import PastorSound from "./assets/sound/artists/joachim_pastor.mp3";
import JorisSound from "./assets/sound/artists/joris_delacroix.mp3";
import JungleSound from "./assets/sound/artists/jungle.mp3";
import KlingandeSound from "./assets/sound/artists/klingande.mp3";
import LocodiceSound from "./assets/sound/artists/locodice.mp3";
import MalaaSound from "./assets/sound/artists/malaa.mp3";
import MochakkSound from "./assets/sound/artists/mochakk.mp3";
import PDMSound from "./assets/sound/artists/pdm.mp3";
import StellaBossiSound from "./assets/sound/artists/stella_bossi.mp3";
import WoraklsSound from "./assets/sound/artists/worakls.mp3";

import Jump1 from "./assets/sound/jump/JUMP1.mp3";
import Jump2 from "./assets/sound/jump/JUMP2.mp3";
import Jump3 from "./assets/sound/jump/JUMP3.mp3";
import Jump4 from "./assets/sound/jump/JUMP4.mp3";
import Jump5 from "./assets/sound/jump/JUMP5.mp3";

export const gemImageUrls = [Gem1, Gem2, Gem3, Gem4, Gem5, Gem6];

export const gravity = 0.5;

export const minimumJumpAcceleration = 20;

export const maximumJumpAcceleration = 8;

export const numberOfGemsToCollect = 100;

export const trainShowsUpAtNumberOfGemsCollected = [20, 40];

export const dinoSpeedRunning = 0.8;

export const dinoSpeedOnTrain = 2.5;

export const trainSpeed = dinoSpeedOnTrain + 0.5;

export const jumps = [Jump1, Jump2, Jump3, Jump4, Jump5];

export const gemArtist = [
  {
    id: 0,
    imageUrl: Argy,
    sound: ArgySound,
    alreadyDisplayed: false,
  },
  {
    id: 1,
    imageUrl: Bakermat,
    sound: BakermatSound,
    alreadyDisplayed: false,
  },
  {
    id: 2,
    imageUrl: BobSinclar,
    sound: BobSinclarSound,
    alreadyDisplayed: false,
  },
  {
    id: 3,
    imageUrl: Feder,
    sound: FederSound,
    alreadyDisplayed: false,
  },
  {
    id: 4,
    imageUrl: Fisher,
    sound: FisherSound,
    alreadyDisplayed: false,
  },
  {
    id: 5,
    imageUrl: Joris,
    sound: JorisSound,
    alreadyDisplayed: false,
  },
  {
    id: 6,
    imageUrl: Jungle,
    sound: JungleSound,
    alreadyDisplayed: false,
  },
  {
    id: 7,
    imageUrl: Klingande,
    sound: KlingandeSound,
    alreadyDisplayed: false,
  },
  {
    id: 8,
    imageUrl: Locodice,
    sound: LocodiceSound,
    alreadyDisplayed: false,
  },
  {
    id: 9,
    imageUrl: Maklovitch,
    sound: MaklovitchSound,
    alreadyDisplayed: false,
  },
  {
    id: 10,
    imageUrl: Malaa,
    sound: MalaaSound,
    alreadyDisplayed: false,
  },
  {
    id: 11,
    imageUrl: Mochakk,
    sound: MochakkSound,
    alreadyDisplayed: false,
  },
  {
    id: 12,
    imageUrl: Pastor,
    sound: PastorSound,
    alreadyDisplayed: false,
  },
  {
    id: 13,
    imageUrl: PDM,
    sound: PDMSound,
    alreadyDisplayed: false,
  },
  {
    id: 14,
    imageUrl: StellaBossi,
    sound: StellaBossiSound,
    alreadyDisplayed: false,
  },
  {
    id: 15,
    imageUrl: Worakls,
    sound: WoraklsSound,
    alreadyDisplayed: false,
  },
];
