import { useNavigate } from "react-router-dom";
import { User } from "../models/User";
import Logo from "../assets/logo.png";
import "./Welcome.css";
import { useState } from "react";

interface Props {
  user: User;
  updateUser: any;
}

export const Welcome = ({ user, updateUser }: Props) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);

  const validateEmail = (email: string) => {
    const regex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
    return regex.test(email);
  };

  return (
    <div className="wrapper">
      <div className="container">
        {currentStep === 0 && (
          <div className="setp-0">
            <img src={Logo} alt="logo" className="logo" />
            <div className="form-item">
              <input
                className="form-input"
                placeholder="Pseudo"
                type="text"
                id="pseudo"
                onChange={(e) => updateUser({ pseudo: e.target.value })}
              />
            </div>
            <div className="form-item">
              <input
                className="form-input"
                placeholder="Email"
                type="email"
                id="mail"
                onChange={(e) => updateUser({ email: e.target.value })}
              />
            </div>
            {user.pseudo && validateEmail(user.email) && (
              <div>
                <button onClick={() => setCurrentStep(1)} className="button">
                  Suivant
                </button>
              </div>
            )}
          </div>
        )}
        {currentStep === 1 && (
          <div className="setp-1">
            <h1 style={{ marginBottom: 20 }}>Regles du jeu</h1>
            <p>
            Sois le plus rapide pour récupérer 100 disques en faisant sauter la disco ball avec la barre espace. 
            </p>
            <p>
            Montes le son ! Ecoute la programmation de cet été à la Clairière en récuperant les artistes au fil du jeu.
            </p>
            <p>
            Tu as jusqu’au 30/04 pour tenter ta chance et finir premier du tableau pour remporter un pass saison valable pour toi et la personne de ton choix ! 
            </p>
            <p>
            Let’s party!
            </p>
            <div style={{ marginTop: 20 }}>
              <button onClick={() => navigate("/game")} className="button">
                Jouer
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
