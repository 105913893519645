import axios from "axios";
import { User } from "../models/User";
import { SCORES_URL } from "../utils/urls";

export async function getScores() {
  return await axios.get(SCORES_URL).then((response) => response.data.data);
}

export async function createScore(user: User) {
  return await axios.post(SCORES_URL, user).then((response) => true);
}
