import App from "../App";
import axios from "axios";
import { FC, useState } from "react";
import { User } from "../models/User";
import { Welcome } from "../pages/Welcome";
import { Route, Routes } from "react-router-dom";
import { setupAxios } from "../utils/setupAxios";
import { Leaderboard } from "../pages/Leaderboard";

const AppRoutes: FC = () => {
  const [user, setUser] = useState<User>({ email: "", pseudo: "", score: 0 });

  const updateUser = (newUser: Partial<User>) => {
    setUser({ ...user, ...newUser });
  };

  setupAxios(axios);

  return (
    <Routes>
      <Route
        path="/"
        element={<Welcome user={user} updateUser={updateUser} />}
      />
      <Route
        path="/game"
        element={<App user={user} updateUser={updateUser} />}
      />
      <Route
        path="/score"
        element={<Leaderboard user={user} updateUser={updateUser} />}
      />
    </Routes>
  );
};

export { AppRoutes };
