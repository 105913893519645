import Gem from "./Gem";
import useGems from "./useGems";

import GemUrl from "./assets/cd2.png";

import "./Gems.css";
import { numberOfGemsToCollect } from "./constants";

interface GemsProps {
  dinoSpeed: number;
  dinoRef: React.RefObject<HTMLDivElement>;
  onStartTrain: () => void;
  onFinishGame: () => void;
  isDinoOnTrain: boolean;
  timeToDisplay: string
}

const Gems = ({
  dinoSpeed,
  dinoRef,
  isDinoOnTrain,
  onStartTrain,
  onFinishGame,
  timeToDisplay

}: GemsProps) => {
  const { gems, gemCounter, onTakeGem } = useGems({
    dinoSpeed,
    onStartTrain,
    onFinishGame,
    isDinoOnTrain,
  });



  return (
    <>
      <div className="counter-container">
        <div className="gem-counter">
        <img src={GemUrl} alt="" />
        <span>{gemCounter}/{numberOfGemsToCollect}</span>
        </div>
        <div className="counter">
          <span>{timeToDisplay}</span>
        </div>
      </div>
      <div className="gems-wrapper">
        {gems.map((gem) => (
          <Gem
            key={gem.id}
            id={gem.id}
            bottom={gem.bottom}
            left={gem.left}
            imageUrl={gem.imageUrl}
            dinoRef={dinoRef}
            onTakeGem={onTakeGem}
            isTaken={gem.isTaken}
            artist={gem.artist}
          />
        ))}
      </div>
    </>
  );
};

export default Gems;
