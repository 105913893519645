import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Score } from "../models/Score";
import { User } from "../models/User";
import { createScore, getScores } from "../service/scoreService";
import "./Leaderboard.css";

interface Props {
  user: User;
  updateUser: any;
}

export const Leaderboard = ({ user, updateUser }: Props) => {
  const navigate = useNavigate();
  const [scores, setScores] = useState<Score[]>([]);

  const storeScore = () => {
    createScore(user).then(() => {
      //TODO: DO SOMETHING
    });
  };
  const fetchScores = () => {
    getScores().then((scores) => setScores(scores));
  };

  useEffect(() => {
    if (!user.email || !user.pseudo) {
      // Redirect to welcome page
      // If the user is not filled
      navigate("/");
    }
    createScore(user).then(() => {
      getScores().then((scores) => setScores(scores));
    });
  }, [navigate, setScores, user.email, user.pseudo]);

  var toHHMMSS = (secs: string) => {
    var sec_num = parseInt(secs, 10)
    var hours   = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
}

  return (
    <>
      <div className="wrapper">
      <div className="container">
        <h1>Bravo {user.pseudo}</h1>
        <h2>Tu as bien joué, ton score est {toHHMMSS(String(user.score))}</h2>
        <h2>Voici le top 10 !</h2>
        <div style={{textAlign: "left"}}>
          <table>
            <thead>
              <tr>
                <th>Rang</th>
                <th>Pseudo</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {scores.map((score,index) => (
                <tr key={score.id}>
                  <td>#{index + 1}</td>
                  <td>{score.pseudo}</td>
                  <td>{toHHMMSS(String(score.score))}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{ marginTop: 20 }}>
          <button onClick={() => navigate("/")} className="button">
            Re-jouer
          </button>
        </div>
      </div>
    </div>
    </>
  );
};
