import Countdown from "react-countdown";
import GameWrapper from "./GameWrapper";
import { useEffect, useRef, useState } from "react";
import { User } from "./models/User";
import { useNavigate } from "react-router-dom";

interface Props {
  user: User;
  updateUser: any;
}

const App = ({ user, updateUser }: Props) => {
  const navigate = useNavigate();

  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);


  const calendarRef = useRef<any | null>(null);

  useEffect(() => {
    setTimeout(function() {
      setIsPlaying(true)
    }, 8000);
  }, []);

  useEffect(() => {

    const interval = setInterval(() => {
      if (isPlaying) {
        setSeconds((prevCounter) => prevCounter + 1)
      }
    }, 1000);
  
    return () => clearInterval(interval);
  }, [isPlaying]);


  const start = () => {
    if (calendarRef.current != null) {
      calendarRef.current.getApi().start();
    }
  };
  const stop = () => {
    //TODO: SET SCORE HERE
    updateUser({ score: seconds });
    navigate("/score");
    if (calendarRef.current != null) {
      calendarRef.current.getApi().pause();
    }
  };

  useEffect(() => {
    if (!user.email || !user.pseudo) {
      // Redirect to welcome page
      // If the user is not filled
      navigate("/");
    }
  }, [navigate, user]);

  var toHHMMSS = (secs: string) => {
    var sec_num = parseInt(secs, 10)
    var hours   = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
}

  return (
    <>
      <GameWrapper
        timeToDisplay={toHHMMSS(String(seconds))}
        onStartGameWrapper={() => start()}
        onFinishGameWrapper={() => stop()}
      />
    </>
  );
};

export default App;
